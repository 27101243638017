<template>
  <div class="columns is-vcentered">
    <div class="column">
      <p class="is-size-5 has-text-info">
        Mileage
      </p>
    </div>
    <div
      :class="[mileage && !editMileage ? 'is-narrow' : '']"
      class="column"
    >
      <a
        v-if="mileage && !editMileage"
        class="field has-text-primary"
        @click="toggleMileage"
      >
        <span>
          {{ formatNumber(mileage) }} miles
          <span v-if="mileageEstimated">(estimated)</span>
        </span>
        <span class="icon is-small">
          <i class="fal fa-pencil" />
        </span>
      </a>
      <div
        v-else-if="editMileage"
        class="columns is-vcentered"
      >
        <div class="column">
          <div class="field has-addons">
            <p class="control is-expanded">
              <input
                v-model.number="customMileage"
                type="number"
                class="input is-rounded is-white has-shadow"
                min="0"
                max="500000"
                placeholder="Enter mileage"
                @keydown.enter="updateMileage"
              >
            </p>
            <p class="control">
              <button
                class="button is-rounded has-background-success-light"
                @click="toggleMileage"
              >
                <span class="icon has-text-danger is-small">
                  <i class="fal fa-times" />
                </span>
              </button>
            </p>
            <p class="control">
              <button
                class="button is-rounded has-background-success-light"
                @click="updateMileage"
              >
                <span class="icon has-text-success is-small">
                  <i class="fal fa-check" />
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="has-text-right">
          <span class="icon has-text-danger">
            <i class="fal fa-exclamation-triangle" />
          </span>
          <span class="has-text-grey">Unable to estimate mileage</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from 'accounting'
export default {
  name: 'MileageEdit',
  props: {
    mileage: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 500000
    },
    mileageEstimated: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    editMileage: false,
    customMileage: null
  }),
  methods: {
    updateMileage() {
      const value = this.customMileage
      if (value >= 0 && value <= 500000) {
        this.$emit('updateMileage', value)
        this.toggleMileage()
      } else {
        this.$notify('Your mileage must be between 0-500k')
      }
    },
    toggleMileage() {
      const action = this.editMileage ? 'close' : 'open'
      this.$emit('track', `${action}_mileage_input`)

      this.editMileage = !this.editMileage
    },
    formatNumber(value) {
      return formatNumber(value)
    }
  }
}
</script>
